<template>
  <app-popup title="This will reset your selections!" :open="openConfirmation" :isConfirmation="true" @dismiss="cancelClear">
    <p>Are you sure you want to start over?</p>
    <template v-slot:footer>
      <app-button @click="cancelClear" color="secondary">Cancel</app-button>
      <app-button @click="openConfirmation = false; clearDataCart();">Proceed</app-button>
    </template>
  </app-popup>
  <div class="flex gap-x-2 mb-4">
    <section class="flex-grow">
      <popup-countries></popup-countries>
    </section>

    <section class="flex-grow max-w-1/2">
      <popup-statistics></popup-statistics>
    </section>

    <section class="flex-grow">
      <popup-subpopulations></popup-subpopulations>
    </section>
  </div>

  <section class="text-center border-t border-border mt-4 pt-4 -mx-4">
    <app-button :color="hasSelections ? 'delete' : 'disabled'" size="l"  @click="hasSelections && confirmClearAll()">Clear All</app-button>
    <app-button :color="canSubmit ? 'focus' : 'disabled'" size="l" @click="canSubmit && $router.push('view_cart')">Proceed to Download</app-button>
  </section>
</template>

<script>

import AppButton from '@/components/AppButton';
import AppPopup from '@/components/AppPopup';
import PopupCountries from '@/components/PopupCountries';
import PopupStatistics from '@/components/PopupStatistics';
import PopupSubpopulations from '@/components/PopupSubpopulations';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      openConfirmation: false
    };
  },
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['canSubmit', 'hasSelections'])
  },

  methods: {
    ...mapActions(['clearDataCart']),

    confirmClearAll() {
      this.openConfirmation = true;
    },
    cancelClear() {
      this.openConfirmation = false;
    }
  },

  components: {
    AppButton,
    AppPopup,
    PopupCountries,
    PopupStatistics,
    PopupSubpopulations
  }
};

</script>

<style lang="scss" scoped>
</style>
