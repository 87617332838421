import { DataLockedError, ValidationError } from './Errors';

class Api {
  performRequest(url, method, params = {}) {
    const hasBody = Object.keys(params || {}).length !== 0;

    const headers = new Headers();
    headers.append('Accept', 'application/json');
    // Read by the olive_branch gem to convert attribute casing
    headers.append('Key-Inflection', 'camel');

    const opts = {
      headers,
      method
    };

    if (hasBody) {
      headers.append('Content-Type', 'application/json');
      opts.body = JSON.stringify(params);
    }

    return fetch(url, opts).then(this.checkStatus);
  }

  async checkStatus(response) {
    if (response.status === 204) {
      return null;
    } else if (response.status === 422) {
      let json = null;
      try {
        json = await response.json();
      } catch {
        // ignore failed json parsing
      }
      throw new ValidationError('Validation Error', json);
    } else if (response.status === 423) {
      throw new DataLockedError('Database is locked');
    } else if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Invalid server response: [${response.status}] ${response.statusText}`);
    }
  }

  get(url, params = {}) {
    const queryParams = [];

    for (const key in params) {
      const val = params[key];
      if (Array.isArray(val)) {
        for (const x of val) {
          queryParams.push(encodeURIComponent(key) + '[]=' + (x === null ? '' : encodeURIComponent(x)));
        }
      } else {
        queryParams.push(encodeURIComponent(key) + '=' + (val === null ? '' : encodeURIComponent(val)));
      }
    }

    if (queryParams.length) {
      url = url + '?' + queryParams.join('&');
    }

    return this.performRequest(url, 'GET');
  }

  post(url, params = {}) {
    return this.performRequest(url, 'POST', params);
  }

  put(url, params = {}) {
    return this.performRequest(url, 'PUT', params);
  }

  getCountries() {
    return this.get('/countries');
  }

  getSubpopulationValues() {
    return this.get('/subpopulation_values');
  }

  getSubpopulationVariables() {
    return this.get('/subpopulation_variables');
  }

  getSubpopulationGroups() {
    return this.get('/subpopulation_groups');
  }

  getStatisticCategories() {
    return this.get('/statistic_categories');
  }

  getStatisticTypes() {
    return this.get('/statistic_types');
  }

  getEarningsMeasureTypes() {
    return this.get('/earnings_measure_types');
  }

  getEarningsMeasureTypeGroups() {
    return this.get('/earnings_measure_type_groups');
  }

  getStatistics() {
    return this.get('/statistics');
  }

  postCreateDataCart(dataCart) {
    const params = this.buildDataCartParams(dataCart);
    return this.post('/data_carts', params);
  }

  putUpdateDataCart(dataCart) {
    const params = this.buildDataCartParams(dataCart);
    return this.put(`/data_carts/${dataCart.id}`, params);
  }

  buildDataCartParams(dataCart) {
    const cartParams = dataCart.toJSON();

    // Convert into Rails-style params
    delete cartParams.id;
    delete cartParams.dataset.id;

    cartParams.dataset.dataType = dataCart.dataset.dataType;
    delete cartParams.dataset.isStatisticsBySkillOnly;

    cartParams.datasetAttributes = cartParams.dataset;
    delete cartParams.dataset;

    return {
      dataCart: cartParams
    };
  }

  postReportError(error) {
    const params = {
      error: error
    };

    return this.post('/report_error', params);
  }
}

export default new Api();
