<template>
  <app-popup title="This will reset your Country selections!" :open="openConfirmation" :isConfirmation="true" @dismiss="cancelClear">
    <p>Are you sure you want clear your Country selections?</p>
    <template v-slot:footer>
      <app-button @click="cancelClear" size="l" color="secondary">Cancel</app-button>
      <app-button @click="openConfirmation = false; clearCountries();" size="l" color="focus">Proceed</app-button>
    </template>
  </app-popup>
  <app-button @click="open" class="w-full sm:w-4/5 min-w-min"><GlobeIcon class="h-5 w-5 mr-2 text-white" /> {{ selectedCountriesText }}</app-button>
  <div v-if="selectedCountries.length > 0">
    <ul class="selections py-3 px-1.5">
      <li v-for="c in selectedCountries" :key="c.id">
        <span>{{ c.name }} ({{ c.startYear }}-{{ c.endYear }})</span><app-delete-button :ariaLabel="`Unselect ${c.name}`" @click="unselectCountry(c.id)"/>
      </li>
    </ul>
    <app-button color="delete" size="s" @click="openConfirmation = true">Clear Countries</app-button>
  </div>
  <div v-else>
    <p class="py-3"><em>No country selected</em></p>
  </div>

  <app-popup title="Countries" :open="popupCountriesOpen" @dismiss="cancel">
    <fieldset>
      <legend class="sr-only">Countries</legend>
      <h3>
        <app-checkbox-item class="my-4 mx-1 selectall" :checked="allCheckboxState" @input="internalToggleAll">
          Select all {{ availableCountries.length }} countries
        </app-checkbox-item>
      </h3>

      <app-checkbox-item v-for="c in availableCountries" :key="c.id" :checked="internalSelectedCountries.has(c.id)" :disabled="!c.enabled" @input="internalToggleCountry(c.id)" class="mx-4 my-2">
        {{ c.name }} ({{ c.startYear }}-{{ c.endYear }})
      </app-checkbox-item>
    </fieldset>

    <template v-slot:footer>
      <app-button @click="save" size="l" color="focus">Save</app-button>
      <app-button @click="cancel" size="l" color="secondary">Cancel</app-button>
    </template>
  </app-popup>
</template>

<script>

import AppButton from '@/components/AppButton';
import AppDeleteButton from '@/components/AppDeleteButton';
import AppCheckboxItem from '@/components/AppCheckboxItem';
import AppPopup from '@/components/AppPopup';
import { mapActions, mapGetters, mapState } from 'vuex';
import { GlobeIcon } from '@heroicons/vue/solid';

export default {
  data() {
    return {
      popupCountriesOpen: false,
      internalSelectedCountries: new Set(),
      openConfirmation: false
    };
  },
  computed: {
    ...mapGetters(['availableCountries', 'selectedCountries', 'selectedCountriesText']),
    ...mapState(['dataCart']),

    enabledAvailableCountries() {
      return this.availableCountries.filter(c => c.enabled);
    },

    allCheckboxState() {
      if (this.internalSelectedCountries.size === 0) {
        return false;
      } else if (this.internalSelectedCountries.size === this.enabledAvailableCountries.length) {
        return true;
      } else {
        return null;
      }
    }
  },

  methods: {
    ...mapActions(['setSelectedCountries']),

    internalToggleCountry(id) {
      if (this.internalSelectedCountries.has(id)) {
        this.internalSelectedCountries.delete(id);
      } else {
        this.internalSelectedCountries.add(id);
      }
    },

    internalToggleAll() {
      if (this.internalSelectedCountries.size !== this.enabledAvailableCountries.length) {
        this.internalSelectedCountries = new Set(this.enabledAvailableCountries.map(c => c.id));
      } else {
        this.internalSelectedCountries.clear();
      }
    },

    unselectCountry(id) {
      const selectedIds = this.selectedCountries.map(c => c.id);
      const idx = selectedIds.indexOf(id);

      if (idx >= 0) {
        selectedIds.splice(idx, 1);
        this.setSelectedCountries({ countryIds: selectedIds });
      }
    },

    clearCountries() {
      this.setSelectedCountries({ countryIds: [] });
      this.internalSelectedCountries.clear();
    },
    cancelClear() {
      this.openConfirmation = false;
    },
    open() {
      this.internalSelectedCountries = new Set([...this.selectedCountries.map(c => c.id)]);
      this.popupCountriesOpen = true;
    },

    cancel() {
      this.internalSelectedCountries = new Set();
      this.popupCountriesOpen = false;
    },

    save() {
      this.setSelectedCountries({ countryIds: [...this.internalSelectedCountries.values()] });
      this.popupCountriesOpen = false;
    }
  },

  components: {
    AppButton,
    AppDeleteButton,
    AppCheckboxItem,
    AppPopup,
    GlobeIcon
  }
};

</script>

<style lang="scss" scoped>
</style>
