<template>
  <app-popup title="Data Types" :open="popupHelpOpen" :isConfirmation="true" @dismiss="ok">
    <div class="mt-4 mb-4">
      <slot></slot>
    </div>
    <template v-slot:footer>
      <app-button @click="ok" color="secondary">OK</app-button>
    </template>
  </app-popup>
  <button type="button" :aria-label="ariaLabel" :aria-disabled="disabled === true" :class="'hover:text-link-ha'" @click="openHelp">
    <question-mark-circle-icon class="h-6 w-6 align-top inline"/>
  </button>
</template>

<script>
import { QuestionMarkCircleIcon } from '@heroicons/vue/solid';
import AppPopup from '@/components/AppPopup';
import AppButton from '@/components/AppButton';

export default {
  data() {
    return {
      popupHelpOpen: false
    };
  },
  props: {
    ariaLabel: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    openHelp() {
      this.popupHelpOpen = true;
    },
    ok() {
      this.popupHelpOpen = false;
    }
  },
  components: {
    QuestionMarkCircleIcon,
    AppPopup,
    AppButton
  }
};
</script>
