<template>
  <app-popup title="Selections will be dropped" :open="popupDroppedSelectionsOpen" :isConfirmation="true" @dismiss="cancel">

    <p class="mb-5">Changing the Data Type will remove the following "{{dataType}}" only selections:</p>
    <div v-if="selectedStatisticsTobeDropped.length">
      <h3>Statistics:</h3>
      <template v-for="(stats, categoryName) in groupStatisticsByCategory(selectedStatisticsTobeDropped)" :key="categoryName">
        <h4>{{ categoryName }}:</h4>
        <ul class="list-disc ml-5 mb-5">
          <li v-for="s in stats" :key="s.id">
            {{s.longLabel}}
          </li>
        </ul>
      </template>
    </div>
    <div v-if="selectedSubpopulationValuesTobeDropped.length">
      <h3>Subpopulations:</h3>
      <template v-for="(values, id) in groupSubpopsByVariableId(selectedSubpopulationValuesTobeDropped)" :key="id">
        <h4>{{ availableSubpopulationVariableMap[id].name }}:</h4>
        <ul class="list-disc ml-5 mb-5">
          <li v-for="s in values" :key="s.id">
            {{ s.label }}
          </li>
        </ul>
      </template>
    </div>
    <template v-slot:footer>
      <app-button @click="cancel" size="l" color="secondary">Cancel</app-button>
      <app-button @click="proceed" size="l" color="focus">Proceed</app-button>
    </template>
  </app-popup>
  <div class="bg-background-light rounded-sm mb-4 p-4">
    <h2>
      Select Data Types
      <app-help-link>
        <p class="mb-4">
          <span class="font-bold">Time series statistics:</span> For each country, statistics are organized by year and
          (if selected) subpopulations (age and gender).
        </p>
        <p>
          <span class="font-bold">Statistics by skill:</span> For each country, statistics are organized by position in
          the distribution of permanent income (41 groups) and (if selected) subpopulations (year, age, and gender).
        </p>
      </app-help-link>
    </h2>
    <fieldset class="mt-4">
      <legend class="sr-only">Select Data Types</legend>
      <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div class="flex items-center">
          <input
              v-model="internalIsStatisticsBySkillOnly"
              @change.stop.prevent="setIsStatisticsBySkillOnlyWithCheck(false)"
              :value="false"
              id="timeseriesStatistics"
              type="radio"
              class="focus:ring-tertiary h-4 w-4 text-tertiary" />
          <label for="timeseriesStatistics" class="ml-3 block">
            Time Series Statistics
          </label>
        </div>
        <div class="flex items-center">
          <input
              v-model="internalIsStatisticsBySkillOnly"
              @change.stop.prevent="setIsStatisticsBySkillOnlyWithCheck(true)"
              :value="true"
              id="statisticsBySkillOnly"
              type="radio"
              class="focus:ring-tertiary h-4 w-4 text-tertiary" />
          <label for="statisticsBySkillOnly" class="ml-3 block">
            Statistics by skill
          </label>
        </div>
      </div>
    </fieldset>
  </div>

  <h2 class="text-center my-8">Customize {{ dataType }} Data Set</h2>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { groupStatisticsByCategory, groupSubpopsByVariableId } from '@/lib/Helpers';
import AppPopup from '@/components/AppPopup';
import AppButton from '@/components/AppButton';
import AppHelpLink from '@/components/AppHelpLink';

export default {
  data() {
    return {
      popupDroppedSelectionsOpen: false,
      internalIsStatisticsBySkillOnly: this.$store.getters.isStatisticsBySkillOnly
    };
  },

  computed: {
    ...mapGetters([
      'availableSubpopulationValues',
      'dataType',
      'selectedStatistics',
      'selectedSubpopulationValues'
    ]),
    ...mapState(['availableSubpopulationVariableMap']),

    selectedStatisticsTobeDropped() {
      return this.selectedStatistics.filter((stat) => stat.isStatisticsBySkillOnly !== this.internalIsStatisticsBySkillOnly);
    },
    selectedSubpopulationValuesTobeDropped() {
      return this.selectedSubpopulationValues.filter((subpopval) => !this.internalIsStatisticsBySkillOnly && subpopval.isStatisticsBySkillOnly);
    }
  },
  methods: {
    ...mapMutations([
      'setIsStatisticsBySkillOnly',
      'removeStatisticFromSelection',
      'removeSubpopulationValueFromSelection'
    ]),
    groupStatisticsByCategory,
    groupSubpopsByVariableId,

    cancel() {
      this.popupDroppedSelectionsOpen = false;
      this.internalIsStatisticsBySkillOnly = this.$store.getters.isStatisticsBySkillOnly;
    },

    proceed() {
      this.selectedStatisticsTobeDropped.forEach((obj) => {
        this.removeStatisticFromSelection(obj.id);
      });
      this.selectedSubpopulationValuesTobeDropped.forEach((obj) => {
        this.removeSubpopulationValueFromSelection(obj.id);
      });
      this.setIsStatisticsBySkillOnly(this.internalIsStatisticsBySkillOnly);

      this.popupDroppedSelectionsOpen = false;
    },

    open() {
      this.popupDroppedSelectionsOpen = true;
    },

    setIsStatisticsBySkillOnlyWithCheck(hetOnlyFlag) {
      if (this.selectedStatisticsTobeDropped.length || this.selectedSubpopulationValuesTobeDropped.length) {
        this.open();
      } else {
        this.proceed();
      }
    }
  },

  components: {
    AppHelpLink,
    AppButton,
    AppPopup
  }
};
</script>
