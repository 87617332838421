<template>
  <component :is="as" v-bind="elementAttributes" :aria-label="ariaLabel" :aria-disabled="color === 'disabled'" :class="configuredClasses" class="text-center inline-flex items-center border border-transparent font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 mx-1">
    <slot></slot>
  </component>
</template>

<script>

const colorConfigs = {
  primary: ['bg-background-dark', 'text-white', 'hover:bg-background', 'focus:ring-tertiary', 'shadow-sm'],
  focus: ['bg-background', 'text-white', 'hover:bg-opacity-80', 'focus:ring-tertiary', 'shadow-sm'],
  secondary: ['bg-nearWhite', 'border-primary', 'text-primary', 'hover:bg-background-ha', 'hover:text-white', 'focus:ring-tertiary', 'shadow-sm'],
  disabled: ['bg-background-medlight', 'text-white', 'focus:ring-warning', 'shadow-sm'],
  delete: ['bg-nearWhite', 'border-primary', 'text-primary', 'hover:bg-background-ha', 'hover:text-white', 'hover:border-tertiary', 'focus:ring-tertiary'],
  deleteLight: ['bg-transparent', 'text-primary', 'hover:bg-background-ha', 'hover:text-link-ha', 'focus:ring-tertiary']

};

const sizeConfigs = {
  xxxs: ['px-0.5', 'py-0.5', 'text-xs', 'rounded-sm'], // delete buttons
  xxs: ['px-2', 'py-0.5', 'text-xs', 'rounded-sm'],
  xs: ['px-2.5', 'py-1.5', 'text-xs', 'rounded-sm'],
  s: ['px-3', 'py-2', 'text-sm', 'leading-4', 'rounded-sm'],
  md: ['px-4', 'py-2', 'text-base', 'rounded-sm'],
  l: ['px-4', 'py-2', 'text-lg', 'rounded-sm']
  // xl: ['px-6', 'py-3', 'text-base', 'rounded-sm']
};

export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: value => {
        return colorConfigs[value] !== undefined;
      }
    },

    size: {
      type: String,
      required: false,
      default: 'md',
      validator: value => {
        return sizeConfigs[value] !== undefined;
      }
    },

    ariaLabel: {
      type: String,
      required: false
    },

    as: {
      type: String,
      required: false,
      default: 'button'
    }
  },

  computed: {
    configuredClasses() {
      return [...(colorConfigs[this.color] || []), ...(sizeConfigs[this.size] || [])];
    },

    elementAttributes() {
      if (this.as === 'button') {
        return { type: 'button' };
      } else {
        return {};
      }
    }
  }
};

</script>
