import { createStore } from 'vuex';
import DataCart from '../lib/DataCart';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export function defaultState() {
  return {
    hasError: false,
    errorMessage: null,
    errorObject: null,
    isLoading: true,
    dataCart: new DataCart(),
    dataCartValidationErrors: null,
    availableCountryIds: null,
    availableCountryMap: null,
    availableEarningsMeasureTypeIds: null,
    availableEarningsMeasureTypeMap: null,
    availableEarningsMeasureTypeGroupIds: null,
    availableEarningsMeasureTypeGroupMap: null,
    availableStatisticIds: null,
    availableStatisticMap: null,
    availableStatisticCategoryIds: null,
    availableStatisticCategoryMap: null,
    availableStatisticTypeIds: null,
    availableStatisticTypeMap: null,
    availableSubpopulationVariableIds: null,
    availableSubpopulationVariableMap: null,
    availableSubpopulationGroupIds: null,
    availableSubpopulationGroupMap: null,
    availableSubpopulationValueIds: null,
    availableSubpopulationValueMap: null,
    statisticSearchCache: []
  };
}

export default createStore({
  strict: process.env.NODE_ENV !== 'production',

  state() {
    return defaultState();
  },

  getters,
  mutations,
  actions,

  modules: { }
});
