import { setStoredCart } from '../lib/DataCartPersistence';

// Given a list that is a subset of masterList, sorts the items in list so they are in the same order as masterList.
// In place sort; returns sorted list
function sortByPosition(list, masterList) {
  const sortMap = new Map();
  masterList.forEach((id, idx) => { sortMap.set(id, idx); });
  list.sort((id1, id2) => sortMap.get(id1) - sortMap.get(id2));
  return list;
}

function getIdsAndMap(objects) {
  const ids = [];
  const map = {};

  objects.forEach(o => {
    ids.push(o.id);
    map[o.id] = o;
  });

  return {
    ids,
    map
  };
}

export default {
  setError(state, payload) {
    const error = payload.error;

    if (console) {
      console.log(error);
    }

    state.isLoading = false;
    state.hasError = true;
    state.errorObject = error;

    if (error && error.message) {
      state.errorMessage = error.message;
    } else if (error) {
      state.errorMessage = error.toString();
    } else {
      state.errorMessage = 'Unknown Error';
    }
  },

  setIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setCountries(state, payload) {
    const { ids, map } = getIdsAndMap(payload.countries);
    state.availableCountryIds = ids;
    state.availableCountryMap = map;
  },

  setSubpopulationValues(state, payload) {
    const { ids, map } = getIdsAndMap(payload.subpopulationValues);
    state.availableSubpopulationValueIds = ids;
    state.availableSubpopulationValueMap = map;
  },

  setSubpopulationVariables(state, payload) {
    const { ids, map } = getIdsAndMap(payload.subpopulationVariables);
    state.availableSubpopulationVariableIds = ids;
    state.availableSubpopulationVariableMap = map;
  },

  setSubpopulationGroups(state, payload) {
    const { ids, map } = getIdsAndMap(payload.subpopulationGroups);
    state.availableSubpopulationGroupIds = ids;
    state.availableSubpopulationGroupMap = map;
  },

  setStatisticCategories(state, payload) {
    const { ids, map } = getIdsAndMap(payload.statisticCategories);
    state.availableStatisticCategoryIds = ids;
    state.availableStatisticCategoryMap = map;
  },

  setStatisticTypes(state, payload) {
    const { ids, map } = getIdsAndMap(payload.statisticTypes);
    state.availableStatisticTypeIds = ids;
    state.availableStatisticTypeMap = map;
  },

  setEarningsMeasureTypes(state, payload) {
    const { ids, map } = getIdsAndMap(payload.earningsMeasureTypes);
    state.availableEarningsMeasureTypeIds = ids;
    state.availableEarningsMeasureTypeMap = map;
  },

  setEarningsMeasureTypeGroups(state, payload) {
    const { ids, map } = getIdsAndMap(payload.earningsMeasureTypeGroups);
    state.availableEarningsMeasureTypeGroupIds = ids;
    state.availableEarningsMeasureTypeGroupMap = map;
  },

  setStatistics(state, payload) {
    const { ids, map } = getIdsAndMap(payload.statistics);
    state.availableStatisticIds = ids;
    state.availableStatisticMap = map;
  },

  updateSearchCache(state) {
    const stats = state.availableStatisticIds.map(id => state.availableStatisticMap[id]);
    const cache = [];

    stats.forEach(stat => {
      cache.push({
        slug: `${stat.name} ${stat.description} ${stat.earningsMeasureType.name} ${stat.earningsMeasureType.statisticType.name}`.toLowerCase(),
        id: stat.id,
        isStatisticsBySkillOnly: stat.isStatisticsBySkillOnly
      });
    });

    state.statisticSearchCache = cache;
  },

  setDataCart(state, { dataCart }) {
    state.dataCart = dataCart;
    state.dataCartValidationErrors = null;
    setStoredCart(state.dataCart);
  },

  setDataCartValidationErrors(state, { validationErrors }) {
    state.dataCartValidationErrors = validationErrors;
  },

  setDataCartCountryIds(state, payload) {
    const newIds = payload.countryIds;
    state.dataCart.dataset.countryIds = sortByPosition(newIds, state.availableCountryIds || []);
    setStoredCart(state.dataCart);
  },

  markSubpopulationDefaultsAdded(state) {
    state.dataCart.subpopulationDefaultsAdded = true;
  },

  setDataCartStatisticIds(state, payload) {
    const newIds = payload.statisticIds;
    state.dataCart.dataset.statisticIds = sortByPosition(newIds, state.availableStatisticIds || []);
    setStoredCart(state.dataCart);
  },

  setSelectedSubpopulationValues(state, payload) {
    const newIds = payload.subpopulationValueIds;
    state.dataCart.dataset.subpopulationValueIds = sortByPosition(newIds, state.availableSubpopulationValueIds || []);
    setStoredCart(state.dataCart);
  },

  closeStatisticTypeDisclosure(state, id) {
    const statType = state.availableStatisticTypeMap[id];
    if (statType) {
      statType.disclosureOpen = false;
    }
  },

  openStatisticTypeDisclosure(state, id) {
    const statType = state.availableStatisticTypeMap[id];
    if (statType) {
      statType.disclosureOpen = true;
    }
  },

  closeSubpopulationVariableDisclosure(state, id) {
    const subpopVariable = state.availableSubpopulationVariableMap[id];
    if (subpopVariable) {
      subpopVariable.disclosureOpen = false;
    }
  },

  openSubpopulationVariableDisclosure(state, id) {
    const subpopVariable = state.availableSubpopulationVariableMap[id];
    if (subpopVariable) {
      subpopVariable.disclosureOpen = true;
    }
  },

  removeStatisticFromSelection(state, payload) {
    const id = payload;
    const index = state.dataCart.dataset.statisticIds.indexOf(id);
    state.dataCart.dataset.statisticIds.splice(index, 1);
    setStoredCart(state.dataCart);
  },

  removeSubpopulationValueFromSelection(state, payload) {
    const id = payload;
    const index = state.dataCart.dataset.subpopulationValueIds.indexOf(id);
    state.dataCart.dataset.subpopulationValueIds.splice(index, 1);
    setStoredCart(state.dataCart);
  },

  setFileFormat(state, payload) {
    state.dataCart.fileFormat = payload;
    setStoredCart(state.dataCart);
  },

  setIsStatisticsBySkillOnly(state, payload) {
    state.dataCart.dataset.isStatisticsBySkillOnly = payload;
    setStoredCart(state.dataCart);
  }
};
