import { groupStatisticsByCategory, groupSubpopsByVariableId } from '@/lib/Helpers';

function filterByDatatype(obj, state) {
  return obj.isStatisticsBySkillOnly === state.dataCart.dataset.isStatisticsBySkillOnly;
}

export default {
  availableCountries(state) {
    return (state.availableCountryIds || []).map(id => state.availableCountryMap[id]);
  },

  availableEarningsMeasureTypes(state) {
    return (state.availableEarningsMeasureTypeIds || []).map(id => state.availableEarningsMeasureTypeMap[id]).filter(emt => filterByDatatype(emt, state));
  },

  availableEarningsMeasureTypeGroups(state) {
    return (state.availableEarningsMeasureTypeGroupIds || []).map(id => state.availableEarningsMeasureTypeGroupMap[id]);
  },

  availableStatistics(state) {
    const stats = (state.availableStatisticIds || []).map(id => state.availableStatisticMap[id]);
    return stats.filter(stat => filterByDatatype(stat, state));
  },

  availableStatisticCategories(state) {
    return (state.availableStatisticCategoryIds || []).map(id => state.availableStatisticCategoryMap[id]);
  },

  availableStatisticTypes(state) {
    return (state.availableStatisticTypeIds || []).map(id => state.availableStatisticTypeMap[id]).filter(statType => filterByDatatype(statType, state));
  },

  statisticTypeDisclosureOpenState: (state) => (id) => {
    if (state.availableStatisticTypeMap[id] !== undefined) {
      return state.availableStatisticTypeMap[id].disclosureOpen || false;
    }
    return false;
  },

  subpopulationVariableDisclosureOpenState: (state) => (id) => {
    if (state.availableSubpopulationVariableMap[id] !== undefined) {
      return state.availableSubpopulationVariableMap[id].disclosureOpen || false;
    }
    return false;
  },

  availableSubpopulationGroups(state) {
    return (state.availableSubpopulationGroupIds || []).map(id => state.availableSubpopulationGroupMap[id]);
  },

  availableSubpopulationVariables(state) {
    if (state.availableSubpopulationVariableIds === null) {
      return [];
    } else {
      return state.availableSubpopulationVariableIds.map(id => state.availableSubpopulationVariableMap[id]).filter(subPopVar => {
        if (subPopVar.isStatisticsBySkillOnly && !filterByDatatype(subPopVar, state)) {
          return false;
        } else {
          return true;
        }
      });
    }
  },

  availableSubpopulationValues(state) {
    if (state.availableSubpopulationValueIds === null) {
      return [];
    } else {
      return state.availableSubpopulationValueIds.map(id => state.availableSubpopulationValueMap[id]).filter(subPopVal => {
        if (subPopVal.isStatisticsBySkillOnly && !filterByDatatype(subPopVal, state)) {
          return false;
        } else {
          return true;
        }
      });
    }
  },

  availableSubpopulationValuesByVariableId(state, getters) {
    return groupSubpopsByVariableId(getters.availableSubpopulationValues);
  },

  selectedCountries(state) {
    if (state.availableCountryIds === null) {
      return [];
    } else {
      return state.dataCart.dataset.countryIds.map(id => state.availableCountryMap[id]);
    }
  },

  selectedStatistics(state) {
    if (state.availableStatisticIds === null) {
      return [];
    } else {
      return state.dataCart.dataset.statisticIds.map(id => state.availableStatisticMap[id]);
    }
  },

  selectedFileIds(state, getters) {
    const fileIds = new Set();
    getters.selectedStatistics.forEach((s) => {
      fileIds.add(s.earningsMeasureType.statisticType.statisticsFileId);
    });
    return [...fileIds];
  },

  selectedSubpopulationValues(state) {
    if (state.availableSubpopulationValueIds === null) {
      return [];
    } else {
      return state.dataCart.dataset.subpopulationValueIds.map(id => state.availableSubpopulationValueMap[id]);
    }
  },

  selectedSubpopulationValuesByVariableId(state, getters) {
    return groupSubpopsByVariableId(getters.selectedSubpopulationValues);
  },

  selectedStatisticsByCategory(state, getters) {
    return groupStatisticsByCategory(getters.selectedStatistics);
  },

  fileFormat(state) {
    return state.dataCart.fileFormat;
  },

  canSubmit(state, getters) {
    return Boolean(
      getters.selectedCountries.length &&
      getters.selectedStatistics.length &&
      getters.selectedSubpopulationValues.length
    );
  },

  hasSelections(state, getters) {
    return Boolean(
      !((getters.selectedCountries.length === 0) &&
        (getters.selectedStatistics.length === 0) &&
        (getters.selectedSubpopulationValues.length === 0))
    );
  },

  selectedCountriesText(state, getters) {
    let text = 'Countries';
    const count = getters.selectedCountries.length;
    if (count) {
      text += ` (${count})`;
    }
    return text;
  },

  selectedStatisticsText(state, getters) {
    let text = 'Statistics';
    const count = getters.selectedStatistics.length;
    if (count) {
      text += ` (${count})`;
    }
    return text;
  },

  selectedSubpopulationValuesText(state, getters) {
    let text = 'Subpopulations';
    const count = getters.selectedSubpopulationValues.length;
    if (count > 0 && getters.selectedCountries.length > 0 && getters.selectedStatistics.length) {
      text += ` (${count})`;
    } else if (count - 2 > 0) {
      text += ` (${count - 2})`;
    }
    return text;
  },

  isStatisticsBySkillOnly(state) {
    return state.dataCart.dataset.isStatisticsBySkillOnly;
  },

  dataType(state) {
    return state.dataCart.dataset.dataTypeLabel;
  }
};
