<template>
  <button role="checkbox" :aria-label="ariaLabel || ''" :aria-checked="checked === null ? 'mixed' : checked === true" :disabled="disabled" :class="checkedClasses" class="inline-block relative w-4 h-4 text-white focus:ring-offset-2 focus:ring-2 focus:ring-primary-med rounded transition-all duration-200">
    <transition name="fade-fast">
      <check-icon v-if="checked === true" class="absolute inset-0"></check-icon>
      <minus-sm-icon v-else-if="checked === null" class="absolute inset-0"></minus-sm-icon>
    </transition>
  </button>
</template>

<script>

import { CheckIcon, MinusSmIcon } from '@heroicons/vue/outline';

export default {
  props: {
    checked: {
      required: false,
      type: Boolean,
      validator: value => {
        return value === true || value === false || value === null;
      }
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false,
      validator: value => {
        return value === true || value === false;
      }
    },

    ariaLabel: {
      required: false,
      type: String
    }
  },

  computed: {
    checkedClasses() {
      if (this.checked === null || this.checked === true) {
        if (this.disabled === true) {
          return ['bg-background-med'];
        } else {
          return ['bg-background-dark'];
        }
      } else {
        if (this.disabled === true) {
          return ['border'];
        } else {
          return ['border-primary', 'border'];
        }
      }
    }
  },

  components: {
    CheckIcon,
    MinusSmIcon
  }
};

</script>
