<template>
  <app-header></app-header>
  <div v-if="isDataLocked">
    <h1>Down for maintenance</h1>
    <p>
      The application should be back up momentarily.
      <a href="/">Click here to refresh and try again.</a>
    </p>
  </div>
  <div v-else-if="isLoading">
    Loading...
  </div>
  <router-view v-else-if="hasError === false"/>
  <div v-else>
    <h1 class="">
      An Unexpected Error Has Occurred
    </h1>
    <h3 class="">
      Our developers have been notified.  We apologize for the inconvenience.
    </h3>
  </div>
</template>

<script>
import api from '@/lib/Api';
import { DataLockedError } from '@/lib/Errors';
import { mapActions, mapMutations, mapState } from 'vuex';
import AppHeader from '@/components/AppHeader';

export default {
  computed: {
    ...mapState(['hasError', 'errorMessage', 'errorObject', 'isLoading']),

    isDataLocked() {
      return this.hasError && this.errorObject instanceof DataLockedError;
    }
  },

  methods: {
    ...mapMutations(['setError', 'setIsLoading']),
    ...mapActions(['loadAllMetadata', 'setupInitialDataCart']),

    prefetchData() {
      this.loadAllMetadata().then(() => {
        this.setIsLoading({ isLoading: false });
        this.setupInitialDataCart();
      });
    },

    reportError(err) {
      if (!err || err instanceof DataLockedError) {
        return;
      }

      try {
        api.postReportError({
          name: err.name || 'Error',
          message: (err.message) ? err.message : err.toString(),
          stack: (err.stack && err.stack.split) ? err.stack.split('\n').filter(l => l.length > 0) : null,
          fileName: err.fileName || null,
          lineNumber: err.lineNumber || null,
          columnNumber: err.columnNumber || null
        });
      } catch (err) {
        console.log(err);
      }
    }
  },

  mounted() {
    this.$watch('hasError', function(newVal, oldVal) {
      if (newVal) {
        this.reportError(this.errorObject);
      }
    });

    const component = this;

    window.onerror = function(message, source, lineno, colno, error) {
      if (!error) {
        error = {
          name: 'Error',
          message: message,
          fileName: source,
          lineNumber: lineno,
          columnNumber: colno
        };
      }

      // Ignore these errors
      if (message.toString().match(/Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules/i)) {
        return;
      }

      component.setError({ error: error });
    };

    window.addEventListener('unhandledrejection', event => {
      const errObj = event.reason;
      component.setError({ error: errObj });
    });

    this.prefetchData();
  },

  components: {
    AppHeader
  }
};

</script>
<style lang="scss">
</style>
