<template>
  <div id="nav" class="py-6 border-b border-border bg-white">
    <div class="mx-auto mt-px mb-0.5 lg:w-11/12 max-w-7xl header md:flex">
      <div class="flex-none">
        <div class="inline-block ml-4 ">
          <a href="https://www.grid-database.org/"><img class="w-logo" src="/img/GRID_logo_lightergray_668x130.png"></a>
        </div>
      </div>
      <nav class="flex-grow md:justify-end flex ml-0.5 pt-4 md:text-right">
        <!-- <nav class="flex  max-w-xl bg-gray-100"> -->
          <a class="flex-initial text-center" href="https://www.grid-database.org/">GRID Project</a>
          <a class="active flex-initial text-center" href="/">Data Center</a>
          <a class="flex-initial" href="https://www.grid-database.org/documentation">Documentation</a>
          <a class="flex-initial" href="https://www.grid-database.org/research">Research</a>
          <a class="flex-initial" href="https://www.grid-database.org/team">Team</a>
        <!-- </nav> -->
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    noPDFAlert: function() {
      alert('This will show a PDF that we have not yet included.');
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
nav {
  a {
    @apply text-link-header no-underline ml-0 md:ml-1 px-2 hover:text-link-ha hover:underline transition duration-200;
    // padding: 15px 10px;
    text-underline-offset: 0.5em;
    &.active {
      @apply underline;
    }
  }
}
</style>
