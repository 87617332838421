import DataCart from './DataCart';

const dataCartKey = '__GID_DATA_CART';
const sessionStore = window.sessionStorage;

export function getStoredCart() {
  const json = sessionStore.getItem(dataCartKey);

  if (json !== null) {
    try {
      return new DataCart(JSON.parse(json));
    } catch (err) {
      sessionStore.removeItem(dataCartKey);
      console.log('Could not load stored cart');
      console.log(err);
    }
  } else {
    return null;
  }
}

export function setStoredCart(dataCart) {
  if (dataCart === null) {
    sessionStore.removeItem(dataCartKey);
  } else {
    sessionStore.setItem(dataCartKey, JSON.stringify(dataCart));
  }
}
