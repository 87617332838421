<template>
  <div class="mx-auto md:mx-4 lg:mx-auto px-4 lg:w-4/5 max-w-7xl" :class='boxify? "border border-primary-light rounded-sm p-4 bg-white" : ""'>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    boxify: {
      type: Boolean,
      default: false
    }
  }
};
</script>
