<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <app-checkbox :id="elementId" :checked="checked" :disabled="disabled" :aria-label="ariaLabel" @click="$emit('input', !checked)"></app-checkbox>
    </div>
    <div class="ml-3">
      <label :for="elementId">
        <slot></slot>
      </label>
    </div>
  </div>
</template>

<script>

import AppCheckbox from '@/components/AppCheckbox';
import { idGenerator } from '@/lib/IdUtils';

export default {
  emits: ['input'],

  props: {
    checked: {
      required: false,
      type: Boolean,
      validator: value => {
        return value === true || value === false || value === null;
      }
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false,
      validator: value => {
        return value === true || value === false;
      }
    },

    ariaLabel: {
      required: false,
      type: String
    }
  },

  data() {
    return {
      elementId: `id${idGenerator()}`
    };
  },

  components: {
    AppCheckbox
  }
};

</script>
