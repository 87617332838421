import './styles/index.scss';

import TWEEN from '@tweenjs/tween.js';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

createApp(App).use(store).use(router).mount('#app');

// Setup global animation loop
function animate() {
  TWEEN.update();
  requestAnimationFrame(animate);
}
animate();
