<template>
    <content-panel class='my-4'>
      <h1>Select Data</h1>
    </content-panel>

    <content-panel :boxify='true'>
      <submit-data-bugs></submit-data-bugs>
      <datatype-editor></datatype-editor>
      <dataset-editor :dataset="dataset" v-if="!isLoading"></dataset-editor>
    </content-panel>
</template>

<script>

import { mapState } from 'vuex';
import ContentPanel from '@/components/ContentPanel';
import DatasetEditor from '@/components/DatasetEditor';
import DatatypeEditor from '@/components/DatatypeEditor';
import SubmitDataBugs from '@/components/SubmitDataBugs';

export default {
  data() {
    return {
      datasetIdx: 0
    };
  },

  computed: {
    ...mapState(['dataCart', 'isLoading']),

    datasets() {
      return [this.dataCart.dataset];
    },

    dataset() {
      return this.datasets[this.datasetIdx];
    }
  },

  methods: {
  },

  components: {
    ContentPanel,
    DatasetEditor,
    DatatypeEditor,
    SubmitDataBugs
  }
};

</script>
