import { idGenerator, idToJson } from './IdUtils';

export default class Dataset {
  constructor(json) {
    this.id = idGenerator();
    this.countryIds = [];
    this.statisticIds = [];
    this.subpopulationValueIds = [];
    this.isStatisticsBySkillOnly = false;

    if (json) {
      Object.assign(this, json);
    }
  }

  get dataTypeLabel() {
    return this.isStatisticsBySkillOnly ? 'Statistics by skill' : 'Time Series Statistics';
  }

  get dataType() {
    return this.isStatisticsBySkillOnly ? 'statisticsBySkill' : 'timeSeriesStatistics';
  }

  toJSON() {
    return {
      id: idToJson(this.id),
      isStatisticsBySkillOnly: this.isStatisticsBySkillOnly,
      countryIds: this.countryIds,
      statisticIds: this.statisticIds,
      subpopulationValueIds: this.subpopulationValueIds
    };
  }
}
