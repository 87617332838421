<template>
  <Disclosure>
    <DisclosureButton @click="toggleOpen" class="flex justify-between py-2 px-3 border-border-med border rounded w-full text-left">
      <slot name="title">
        <span>{{ this.title }}</span>
      </slot>
      <ChevronRightIcon
          :class="stateIsOpen ? 'transform rotate-90' : ''"
          class="w-5 h-5 text-primary transition-transform"
      />
    </DisclosureButton>
    <app-height-transition>
      <DisclosurePanel v-show="stateIsOpen" static class="">
        <slot></slot>
      </DisclosurePanel>
    </app-height-transition>
  </Disclosure>
</template>

<script>

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import AppHeightTransition from '@/components/AppHeightTransition';

export default {
  props: {
    title: {
      required: false,
      type: String,
      default: ''
    },
    itemId: {
      required: true,
      type: [Number, String]
    },
    // Open state required to be tracked in vuex
    openStateMutatorName: {
      required: true,
      type: String
    },
    closeStateMutatorName: {
      required: true,
      type: String
    },
    // Required function getter that takes id as argument
    openStateGetterName: {
      required: true,
      type: String
    }
  },
  computed: {
    stateIsOpen() {
      return this.$store.getters[this.openStateGetterName](this.itemId);
    }
  },
  methods: {
    toggleOpen() {
      this.stateIsOpen ? this.closeMutation() : this.openMutation();
    },
    openMutation() {
      this.$store.commit(this.openStateMutatorName, this.itemId);
    },
    closeMutation() {
      this.$store.commit(this.closeStateMutatorName, this.itemId);
    }
  },
  components: {
    AppHeightTransition,
    ChevronRightIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  }
};

</script>
