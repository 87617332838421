import Dataset from './Dataset';
import { idGenerator, idToJson } from './IdUtils';

export default class DataCart {
  constructor(json) {
    this.id = idGenerator();
    this.dataset = new Dataset();
    this.subpopulationDefaultsAdded = false;
    this.__fileFormat = 'csv';

    if (json) {
      Object.assign(this, json);
    }
  }

  set dataset(val) {
    if (val === null || val instanceof Dataset) {
      this.__dataset = val;
    } else {
      this.__dataset = new Dataset(val);
    }
  }

  get dataset() {
    return this.__dataset;
  }

  set fileFormat(val) {
    const allowedFormats = ['csv', 'stata'];
    if (allowedFormats.includes(val)) {
      this.__fileFormat = val;
    } else {
      throw Error(`Not setting data cart file format: ${String(val)} not one of ${allowedFormats.join()}`);
    }
  }

  get fileFormat() {
    return this.__fileFormat;
  }

  toJSON() {
    return {
      id: idToJson(this.id),
      dataset: this.dataset.toJSON(),
      fileFormat: this.fileFormat,
      subpopulationDefaultsAdded: this.subpopulationDefaultsAdded
    };
  }
}
