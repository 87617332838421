<template>
  <span :class="configuredClasses" class="inline-flex items-center py-0.5 rounded-full font-medium">
    <slot></slot>
  </span>
</template>

<script>

const colorConfigs = {
  primary: ['bg-background-dark', 'text-white', 'shadow-sm'],
  secondary: ['bg-background-dark', 'text-primary', 'shadow-sm']
};

const sizeConfigs = {
  s: ['px-2.5', 'text-xs'],
  l: ['px-3', 'text-sm']
};

export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: value => {
        return colorConfigs[value] !== undefined;
      }
    },

    size: {
      type: String,
      required: false,
      default: 's',
      validator: value => {
        return sizeConfigs[value] !== undefined;
      }
    }
  },

  computed: {
    configuredClasses() {
      return [...(colorConfigs[this.color] || []), ...(sizeConfigs[this.size] || [])];
    }
  }
};

</script>
