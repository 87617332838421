import api from '../lib/Api';
import DataCart from '../lib/DataCart';
import { getStoredCart } from '../lib/DataCartPersistence';
import { fetchMetadata } from '../lib/MetadataLoader';
import { ValidationError } from '../lib/Errors';

export default {
  async searchStatistics({ state }, payload) {
    let query = payload.query;
    const maxResults = payload.maxResults || 25;
    const statisticsBySkillFlag = state.dataCart.dataset.isStatisticsBySkillOnly;

    if (query && query.length && query.length > 2) {
      query = query.toLowerCase().split(' ');
      const ids = [];
      for (const ci of state.statisticSearchCache) {
        if (ci.isStatisticsBySkillOnly === statisticsBySkillFlag && query.every(q => ci.slug.indexOf(q) >= 0)) {
          ids.push(ci.id);
        }
        if (ids.length >= maxResults) {
          break;
        }
      }
      return ids.map(id => state.availableStatisticMap[id]);
    } else {
      return [];
    }
  },

  async setSelectedCountries({ commit, dispatch }, { countryIds }) {
    commit('setDataCartCountryIds', { countryIds });
    return dispatch('ensureDefaultSubpopulationSelections');
  },

  async setSelectedStatistics({ commit, dispatch }, { statisticIds }) {
    commit('setDataCartStatisticIds', { statisticIds });
    return dispatch('ensureDefaultSubpopulationSelections');
  },

  async ensureDefaultSubpopulationSelections({ commit, getters, state }) {
    const cart = state.dataCart;
    const dataset = cart.dataset;
    if (cart && dataset && !cart.subpopulationDefaultsAdded && dataset.countryIds.length > 0 && dataset.statisticIds.length > 0) {
      const subpopulationValueIds = getters.availableSubpopulationValues.filter(spv => spv.selectionType === 'default').map(spv => spv.id);
      commit('setSelectedSubpopulationValues', { subpopulationValueIds });
      commit('markSubpopulationDefaultsAdded');
    }
  },

  async saveDataCart({ commit, state }) {
    // Create cart clone
    const cart = new DataCart(state.dataCart.toJSON());
    let returnedCart = null;

    try {
      if (cart.id > 0) {
        returnedCart = await api.putUpdateDataCart(cart);
      } else {
        returnedCart = await api.postCreateDataCart(cart);
      }

      cart.id = returnedCart.id;

      commit('setDataCart', { dataCart: cart });
    } catch (err) {
      if (err instanceof ValidationError) {
        commit('setDataCartValidationErrors', { validationErrors: err.validationErrors() });
      } else {
        throw err;
      }
    }
  },

  async clearDataCart({ commit, getters }) {
    const dataCart = new DataCart();
    commit('setDataCart', { dataCart });
  },

  async setupInitialDataCart({ commit, dispatch, getters }) {
    const dataCart = getStoredCart();
    if (dataCart && dataCart.dataset) {
      const dataset = dataCart.dataset;
      if (dataset.countryIds.length > 0 || dataset.statisticIds.length > 0 || (dataset.subpopulationValueIds.length > 0 && !getters.selectedSubpopulationValues.every(sv => sv.selectionType === 'default'))) {
        return commit('setDataCart', { dataCart });
      }
    }
    return dispatch('clearDataCart');
  },

  async loadAllMetadata({ commit }) {
    return fetchMetadata().then(metadata => {
      commit('setCountries', metadata);
      commit('setSubpopulationGroups', metadata);
      commit('setSubpopulationVariables', metadata);
      commit('setSubpopulationValues', metadata);
      commit('setStatisticCategories', metadata);
      commit('setStatisticTypes', metadata);
      commit('setEarningsMeasureTypes', metadata);
      commit('setEarningsMeasureTypeGroups', metadata);
      commit('setStatistics', metadata);

      commit('updateSearchCache');
    });
  }
};
