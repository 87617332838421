<template>
  <app-popup title="This will reset your Statistics selections!" :open="openConfirmation" :isConfirmation="true" @dismiss="cancelClear">
    <p>Are you sure you want clear your Statistics selections?</p>
    <template v-slot:footer>
      <app-button @click="cancelClear" size="l" color="secondary">Cancel</app-button>
      <app-button @click="openConfirmation = false; clearAll();" size="l" color="focus">Proceed</app-button>
    </template>
  </app-popup>
  <app-button @click="open" class="w-full sm:w-4/5 min-w-min"><CalculatorIcon class="h-5 w-5 mr-2 text-white" />{{ selectedStatisticsText }}</app-button>

  <div v-if="selectedStatistics.length > 0" class="py-3">
      <div v-for="(stats, categoryName) in selectedStatisticsByCategory" :key="categoryName" class="px-1.5 mb-4">

        <h3>{{ categoryName }}:</h3>
        <ul class="selections px-3">
          <li v-for="s in stats" :key="s.id">
            <span>{{ s.longLabel }}</span><app-delete-button :ariaLabel="`Unselect ${s.description}`" @click="unselectStatistic(s.id)"/>
          </li>
        </ul>
      </div>
    <app-button color="delete" size="s" @click="openConfirmation = true">Clear Statistics</app-button>
  </div>
  <div v-else>
    <p class="py-3"><em>No statistics selected</em></p>
  </div>

  <app-popup title="Select Statistics" :open="popupStatisticsOpen" @dismiss="cancel">

    <statistic-search :selected-statistic-ids="internalSelectedStatistics" @select="internalSelectStatistics" @unselect="internalUnselectStatistics"></statistic-search>

    <div v-for="statCategory in availableStatisticCategories" :key="statCategory.id">
      <div class="pt-6 pb-2" v-if="showCategory(statCategory)">
        <h3 class="inline">{{ statCategory.name }}</h3> <span class="text-sm tracking-tight"> {{ formattedCategoryDescription(statCategory.description) }}</span>
        <app-button v-if="anyStatisticTypeDisclosureOpenForCategory(statCategory)" size="xxs" @click="collapseStatTypes(statCategory)">Collapse All</app-button>
        <app-button v-else size="xxs" @click="expandStatTypes(statCategory)">Expand All</app-button>
      </div>

      <div class="pl-3">
        <statistic-type-disclosure
            class="mt-2" v-for="statType in enabledForDataType(statCategory.statisticTypes)"
            :key="statType.id"
            :statistic-type="statType"
            :selected-statistic-ids="internalSelectedStatistics"
            @unselect="internalUnselectStatistics"
            @select="internalSelectStatistics"
        >
        </statistic-type-disclosure>
      </div>
    </div>

    <template v-slot:footer>
      <app-button @click="save">Save</app-button>
      <app-button @click="cancel" color="secondary">Cancel</app-button>
    </template>
  </app-popup>
</template>

<script>

import AppButton from '@/components/AppButton';
import AppDeleteButton from '@/components/AppDeleteButton';
import AppPopup from '@/components/AppPopup';
import StatisticSearch from '@/components/PopupStatistics/StatisticSearch';
import StatisticTypeDisclosure from '@/components/PopupStatistics/StatisticTypeDisclosure';
import { CalculatorIcon } from '@heroicons/vue/solid';

import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      popupStatisticsOpen: false,
      internalSelectedStatistics: new Set(),
      openConfirmation: false
    };
  },

  computed: {
    ...mapGetters([
      'statisticTypeDisclosureOpenState',
      'availableStatisticCategories',
      'availableStatisticTypes',
      'availableEarningsMeasureTypes',
      'availableStatistics',
      'selectedStatistics',
      'selectedStatisticsText',
      'selectedStatisticsByCategory',
      'isStatisticsBySkillOnly'
    ])
  },

  methods: {
    ...mapActions(['setSelectedStatistics']),
    ...mapMutations([
      'removeStatisticFromSelection',
      'openStatisticTypeDisclosure',
      'closeStatisticTypeDisclosure'
    ]),

    anyStatisticTypeDisclosureOpenForCategory(statCategory) {
      for (const type of statCategory.statisticTypes) {
        if (this.statisticTypeDisclosureOpenState(type.id)) {
          return true;
        }
      }
      return false;
    },

    internalSelectStatistics(ids) {
      ids.forEach(id => {
        this.internalSelectedStatistics.add(id);
      });
    },

    internalUnselectStatistics(ids) {
      ids.forEach(id => {
        this.internalSelectedStatistics.delete(id);
      });
    },

    expandStatTypes(statCategory) {
      for (const type of statCategory.statisticTypes) {
        this.openStatisticTypeDisclosure(type.id);
      }
    },

    collapseStatTypes(statCategory) {
      for (const type of statCategory.statisticTypes) {
        this.closeStatisticTypeDisclosure(type.id);
      }
    },

    unselectStatistic(id) {
      this.removeStatisticFromSelection(id);
    },

    clearAll() {
      this.setSelectedStatistics({ statisticIds: [] });
    },
    cancelClear() {
      this.openConfirmation = false;
    },

    open() {
      this.internalSelectedStatistics = new Set(this.selectedStatistics.map(s => s.id));
      this.popupStatisticsOpen = true;
    },

    cancel() {
      this.popupStatisticsOpen = false;
    },

    save() {
      this.setSelectedStatistics({ statisticIds: [...this.internalSelectedStatistics.values()] });
      this.popupStatisticsOpen = false;
    },

    showCategory(statCategory) {
      const stIds = new Set(statCategory.statisticTypes.map(st => st.id));
      const enabledstIds = new Set(this.availableStatisticTypes.map(st => st.id));
      const intersection = [...stIds].filter(st => enabledstIds.has(st));

      return intersection.length !== 0;
    },
    formattedCategoryDescription(description) {
      return description != null && description.length > 0 ? '(' + description + ')' : '';
    },
    enabledForDataType(statTypes) {
      const filteredArr = statTypes.filter((statType) => {
        if (this.isStatisticsBySkillOnly === statType.isStatisticsBySkillOnly) {
          return true;
        } else {
          return false;
        }
      });
      return filteredArr.sort((a, b) => a.order - b.order);
    }
  },

  components: {
    AppButton,
    AppDeleteButton,
    AppPopup,
    CalculatorIcon,
    StatisticSearch,
    StatisticTypeDisclosure
  }
};

</script>

<style lang="scss" scoped>
</style>
