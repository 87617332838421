import { createRouter, createWebHashHistory } from 'vue-router';
import SelectData from '../views/SelectData';

const routes = [
  {
    path: '/',
    redirect: '/select_data'
  },
  {
    path: '/select_data',
    name: 'SelectData',
    component: SelectData
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/view_cart',
    name: 'ViewCart',
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewCart.vue')
    // component: 'ViewCart'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes
});

export default router;
