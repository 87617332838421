<template>
  <app-disclosure-row
      as="div"
      v-if="allStatistics.length > 0"
      :itemId="statisticType.id"
      openStateMutatorName="openStatisticTypeDisclosure"
      closeStateMutatorName="closeStatisticTypeDisclosure"
      openStateGetterName="statisticTypeDisclosureOpenState"
  >
    <template v-slot:title="props">
      <span :class="{ [`statistic-type-disclosure-closed-title-${statisticType.id}`]: !props.open }" class="flex-shrink">{{ statisticType.name }} ({{ selfSelectedIds.size }} of {{ availableStatistics.length }})</span>
      <span v-if="selfSelectedIds.size > 0" aria-hidden="true" class="flex-grow pl-2">
        <span class="inline-block w-5 h-5 rounded-full bg-success"></span>
      </span>
    </template>
    <div class="pl-4">
      <app-checkbox-item class="mt-3 selectall" :checked="allCheckboxState" @input="toggleAllStatistics"
                         :disabled="allCheckboxDisabled">Select all {{ availableStatistics.length }} available indicators
      </app-checkbox-item>

      <div v-for="emt in statisticType.earningsMeasureTypes" :key="emt.id">
        <h3><app-checkbox-item class ="mt-3" :checked="emtCheckboxState(emt)" @input="toggleEmtStatistics(emt)" :disabled="emtCheckboxDisabled(emt)">{{ emt.name }}</app-checkbox-item></h3>

        <template v-for="group in statsByEmtGroup(emt)" :key="group.id">
          <app-checkbox-item class="pl-4 my-1" v-if="group.id > 0" :checked="emtGroupCheckboxState(group)" @input="toggleEmtGroupStatistics(group)" :disabled="emtGroupCheckboxDisabled(group)">{{ group.name }}</app-checkbox-item>
          <app-checkbox-item :class="{'pl-4': group.id <= 0, 'pl-8': group.id > 0}" class="my-1" v-for="stat in group.statistics" :key="stat.id" :checked="selectedStatisticIds.has(stat.id)" :disabled="!stat.enabled" @input="toggleStatistic(stat.id)">
            {{ stat.description }}
          </app-checkbox-item>
        </template>
      </div>
    </div>
  </app-disclosure-row>
</template>

<script>

import AppCheckboxItem from '@/components/AppCheckboxItem';
import AppDisclosureRow from '@/components/AppDisclosureRow';
import { idGenerator } from '../../lib/IdUtils';

export default {
  emits: ['unselect', 'select'],

  props: {
    statisticType: {
      type: Object,
      required: true
    },

    selectedStatisticIds: {
      type: Set,
      required: true
    }
  },

  computed: {
    allStatistics() {
      const stats = [];
      for (const emt of this.statisticType.earningsMeasureTypes) {
        for (const stat of emt.statistics) {
          stats.push(stat);
        }
      }

      return stats;
    },

    selfSelectedIds() {
      const allIds = new Set(this.allStatistics.map(s => s.id));
      return new Set([...this.selectedStatisticIds.values()].filter(id => allIds.has(id)));
    },

    allCheckboxState() {
      if (this.selfSelectedIds.size === 0) {
        return false;
      } else if (this.selfSelectedIds.size === this.availableStatistics.length) {
        return true;
      } else {
        return null;
      }
    },

    availableStatistics() {
      return this.allStatistics.filter(s => s.enabled);
    },

    allCheckboxDisabled() {
      return this.availableStatistics.length === 0;
    }
  },

  methods: {
    emtCheckboxDisabled(emt) {
      return this.availableEmtStatistics(emt).length === 0;
    },

    emtGroupCheckboxDisabled(group) {
      return this.availableEmtGroupStatistics(group).length === 0;
    },

    toggleStatistic(id) {
      if (this.selectedStatisticIds.has(id)) {
        this.$emit('unselect', [id]);
      } else {
        this.$emit('select', [id]);
      }
    },

    toggleAllStatistics() {
      if (this.allCheckboxState === true) {
        this.$emit('unselect', this.availableStatistics.map(s => s.id));
      } else {
        this.$emit('select', this.availableStatistics.map(s => s.id));
      }
    },

    emtStatistics(emt) {
      const emtstats = [];
      for (const stat of emt.statistics) {
        emtstats.push(stat);
      }

      return emtstats;
    },

    availableEmtStatistics(emt) {
      return this.emtStatistics(emt).filter(s => s.enabled);
    },

    emtSelectedIds(emt) {
      const emtIds = new Set(this.emtStatistics(emt).map(s => s.id));
      return [...this.selectedStatisticIds.values()].filter(id => emtIds.has(id));
    },

    emtCheckboxState(emt) {
      if (this.emtSelectedIds(emt).length === 0) {
        return false;
      } else if (this.emtSelectedIds(emt).length === this.availableEmtStatistics(emt).length) {
        return true;
      } else {
        return null;
      }
    },

    toggleEmtStatistics(emt) {
      if (this.emtCheckboxState(emt) === true) {
        this.$emit('unselect', this.availableEmtStatistics(emt).map(s => s.id));
      } else {
        this.$emit('select', this.availableEmtStatistics(emt).map(s => s.id));
      }
    },

    statsByEmtGroup(emt) {
      const emptyGroupGenerator = () => ({ name: null, id: idGenerator() });
      const groups = [];
      let curGroup = null;
      let curPlaceholder = null;

      for (const stat of this.emtStatistics(emt)) {
        const statGroup = stat.earningsMeasureTypeGroup || emptyGroupGenerator();
        if (statGroup !== curGroup) {
          curGroup = statGroup;
          curPlaceholder = {
            earningsMeasureTypeGroup: statGroup,
            statistics: [],
            id: statGroup.id,
            name: statGroup.name
          };
          groups.push(curPlaceholder);
        }
        curPlaceholder.statistics.push(stat);
      }

      return groups;
    },

    availableEmtGroupStatistics(group) {
      return group.statistics.filter(s => s.enabled);
    },

    emtGroupCheckboxState(group) {
      const selectedCount = group.statistics.filter(s => this.selfSelectedIds.has(s.id)).length;
      if (selectedCount === 0) {
        return false;
      } else if (selectedCount === this.availableEmtGroupStatistics(group).length) {
        return true;
      } else {
        return null;
      }
    },

    toggleEmtGroupStatistics(group) {
      if (this.emtGroupCheckboxState(group) === true) {
        this.$emit('unselect', this.availableEmtGroupStatistics(group).map(s => s.id));
      } else {
        this.$emit('select', this.availableEmtGroupStatistics(group).map(s => s.id));
      }
    }
  },

  components: {
    AppCheckboxItem,
    AppDisclosureRow
  }
};

</script>
