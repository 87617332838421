import api from './Api';

export function buildIdMap(collection) {
  const map = new Map();
  for (const item of collection) {
    map.set(item.id, item);
  }
  return map;
}

// Fetches all metadata from the API and assigns associations and calculated properties
export async function fetchMetadata() {
  const metadata = {};

  const promises = [
    api.getCountries().then(data => { metadata.countries = data; }),
    api.getSubpopulationGroups().then(data => { metadata.subpopulationGroups = data; }),
    api.getSubpopulationVariables().then(data => { metadata.subpopulationVariables = data; }),
    api.getSubpopulationValues().then(data => { metadata.subpopulationValues = data; }),
    api.getStatisticCategories().then(data => { metadata.statisticCategories = data; }),
    api.getStatisticTypes().then(data => { metadata.statisticTypes = data; }),
    api.getEarningsMeasureTypes().then(data => { metadata.earningsMeasureTypes = data; }),
    api.getEarningsMeasureTypeGroups().then(data => { metadata.earningsMeasureTypeGroups = data; }),
    api.getStatistics().then(data => { metadata.statistics = data; })
  ];

  await Promise.all(promises);

  setAssociations(metadata);

  return metadata;
}

export function setAssociations(metadata) {
  const statisticCategories = buildIdMap(metadata.statisticCategories);
  const statisticTypes = buildIdMap(metadata.statisticTypes);
  const earningsMeasureTypes = buildIdMap(metadata.earningsMeasureTypes);
  const earningsMeasureTypeGroups = buildIdMap(metadata.earningsMeasureTypeGroups);
  const statistics = buildIdMap(metadata.statistics);
  const subpopulationValues = buildIdMap(metadata.subpopulationValues);
  const subpopulationVariables = buildIdMap(metadata.subpopulationVariables);
  const subpopulationGroups = buildIdMap(metadata.subpopulationGroups);

  for (const sc of statisticCategories.values()) {
    sc.statisticTypes = [];
  }

  for (const st of statisticTypes.values()) {
    const sc = statisticCategories.get(st.statisticCategoryId);
    st.earningsMeasureTypes = [];
    st.disclosureOpen = false;
    st.statisticCategory = sc;
    if (sc) {
      sc.statisticTypes.push(st);
    }
  }

  for (const emt of earningsMeasureTypes.values()) {
    const st = statisticTypes.get(emt.statisticTypeId);
    emt.earningsMeasureTypeGroups = [];
    emt.statistics = [];
    emt.statisticType = st;
    emt.isStatisticsBySkillOnly = st.isStatisticsBySkillOnly;
    st.earningsMeasureTypes.push(emt);
  }

  for (const emtg of earningsMeasureTypeGroups.values()) {
    const emt = earningsMeasureTypes.get(emtg.earningsMeasureTypeId);
    emtg.statistics = [];
    emtg.earningsMeasureType = emt;
    emt.earningsMeasureTypeGroups.push(emtg);
  }

  for (const s of statistics.values()) {
    const emt = earningsMeasureTypes.get(s.earningsMeasureTypeId);
    s.earningsMeasureType = emt;
    s.isStatisticsBySkillOnly = emt.isStatisticsBySkillOnly || false;
    emt.statistics.push(s);

    if (s.earningsMeasureTypeGroupId) {
      const emtg = earningsMeasureTypeGroups.get(s.earningsMeasureTypeGroupId);
      s.earningsMeasureTypeGroup = emtg;
      emtg.statistics.push(s);
    }
  }

  for (const subPopVal of subpopulationValues.values()) {
    const supPopVar = subpopulationVariables.get(subPopVal.subpopulationVariableId);
    const supPopGroup = subpopulationGroups.get(subPopVal.subpopulationGroupId);
    subPopVal.subpopulationVariable = supPopVar;
    subPopVal.subpopulationGroup = supPopGroup;
    subPopVal.isStatisticsBySkillOnly = supPopVar.isStatisticsBySkillOnly;
  }

  for (const subPopVar of subpopulationVariables.values()) {
    subPopVar.disclosureOpen = false;
  }
}
