let id = 0;

export function idGenerator() {
  id = id - 1;
  return id;
};

export function idToJson(id) {
  if (parseInt(id) < 0) {
    return null;
  } else {
    return id;
  }
};
