class BaseError extends Error {
  constructor(...params) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.name = this.constructor.name;
  }
}

export class ValidationError extends BaseError {
  constructor(message, json) {
    super(message);
    this.json = json;
  }

  validationErrors() {
    const errors = {};
    if (this.json) {
      for (const key in this.json) {
        errors[key] = this.json[key];
      }
    } else {
      errors.base = ['unknown error'];
    }
    return errors;
  }
}

export class DataLockedError extends BaseError {
}
