<template>
  <div ref="wrapper" @keydown="keydownHandler">
    <div class="mt-1 relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon v-if="!isSearching" class="h-5 w-5 text-primary-med" aria-hidden="true"></SearchIcon>
        <CogIcon v-else class="h-5 w-5 text-tertiary animate-spin" aria-hidden="true"></CogIcon>
      </div>
      <input
        v-model="query"
        @focus="focusHandler"
        type="text"
        name="search"
        autocomplete="off"
        class="focus:ring-primary-med focus:border-primary-med block w-full pl-10 sm:text-sm border-border rounded-md"
        placeholder="Search Here" />
      <div v-if="listOpen" @click="listOpen = false" class="absolute inset-y-0 right-0 pr-3 flex items-center">
        <XIcon class="h-5 w-5 text-link-header hover:text-link-ha" aria-hidden="true" />
      </div>
    </div>

    <div class="relative">
      <ul v-if="listOpen" class="absolute z-10 bg-white w-full border">
        <li v-for="(r, idx) in searchResults" :key="r.id" @mousemove="itemMousemove(idx)" :class="itemClass(idx)" class=" py-1 px-2">
          <app-checkbox-item :checked="selectedStatisticIds.has(r.id)" @input="itemClick(r)">
            {{ r.longLabel }}
          </app-checkbox-item>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import AppCheckboxItem from '@/components/AppCheckboxItem';
import { CogIcon, SearchIcon, XIcon } from '@heroicons/vue/solid';
import { debounce } from '@/lib/FunctionUtils';
import { mapActions } from 'vuex';

export default {
  emits: ['select', 'unselect'],

  props: {
    selectedStatisticIds: {
      type: Set,
      required: true
    }
  },

  data() {
    return {
      listOpen: false,
      query: '',
      searchResults: [],
      activeListIndex: 0,
      isSearching: false
    };
  },

  methods: {
    ...mapActions(['searchStatistics']),

    debouncedSearchStatistics: debounce(function() {
      this.searchStatistics({ query: this.query, maxResults: 25 }).then(results => {
        this.isSearching = false;
        this.searchResults = results;
        this.listOpen = results.length > 0;
        this.activeListIndex = 0;
      });
    }, 500),

    selectOption(stat) {
      if (this.selectedStatisticIds.has(stat.id)) {
        this.$emit('unselect', [stat.id]);
      } else {
        this.$emit('select', [stat.id]);
      }
    },

    itemClass(idx) {
      if (idx === this.activeListIndex) {
        return ['bg-background-med', 'text-white'];
      } else {
        return [];
      }
    },

    itemMousemove(idx) {
      this.activeListIndex = idx;
    },

    itemClick(item) {
      this.selectOption(item);
    },

    focusHandler() {
      if (this.searchResults.length > 0) {
        this.listOpen = true;
      }
    },

    keydownHandler(evt) {
      if (this.listOpen === false) {
        return;
      }

      switch (evt.key) {
        case 'ArrowUp':
          evt.preventDefault();
          this.activeListIndex = Math.max(0, this.activeListIndex - 1);
          break;
        case 'ArrowDown':
          evt.preventDefault();
          this.activeListIndex = Math.min(this.searchResults.length - 1, this.activeListIndex + 1);
          break;
        case 'Enter':
          evt.preventDefault();
          this.selectOption(this.searchResults[this.activeListIndex]);
          break;
        case 'Escape':
          evt.preventDefault();
          evt.stopPropagation();
          this.listOpen = false;
          break;
      }
    },

    handleDocumentClick(evt) {
      if (!this.$refs.wrapper.contains(evt.target)) {
        this.listOpen = false;
      }
    }
  },

  watch: {
    query(val) {
      if (val && val.length) {
        this.isSearching = true;
      }

      this.debouncedSearchStatistics();
    }
  },

  mounted() {
    document.addEventListener('click', this.handleDocumentClick, true);
  },

  unmounted() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  },

  components: {
    AppCheckboxItem,
    CogIcon,
    SearchIcon,
    XIcon
  }
};

</script>
