<template>
  <app-popup title="This will reset your Statistics selections!" :open="openConfirmation" :isConfirmation="true" @dismiss="cancelClear">
    <p>Are you sure you want clear your Statistics selections?</p>
    <template v-slot:footer>
      <app-button @click="cancelClear" size="l" color="secondary">Cancel</app-button>
      <app-button @click="openConfirmation = false; clearSubpopulationValues();" size="l" color="focus">Proceed</app-button>
    </template>
  </app-popup>
  <app-button @click="open" class="w-full sm:w-4/5 min-w-min"><UserIcon class="h-5 w-5 mr-2 text-white" />{{ selectedSubpopulationValuesText }}</app-button>
  <div v-if="selectedSubpopulationValues.length > 0" class="py-3">
    <template v-for="(values, id) in selectedSubpopulationValuesByVariableId" :key="id">
      <div class="mb-4 px-1.5">
        <h3>{{ availableSubpopulationVariableMap[id].name }}:</h3>
        <ul class="selections px-3">
          <li v-for="s in values" :key="s.id">
            <span class="tracking-tighter align-middle">{{ s.label }}</span>
            <app-badge class="ml-1.5" v-if="s.selectionType === 'default'">Default</app-badge>
            <app-delete-button :ariaLabel="`Unselect ${s.label}`" @click="unselectSubpopulationValue(s.id)"/>
          </li>
        </ul>
      </div>
    </template>
    <app-button color="delete" size="s" @click="openConfirmation = true">Clear Subpopulations</app-button>
  </div>
  <div v-else>
    <p class="py-3"><em>No sub-populations selected</em></p>
  </div>

  <app-popup title="Select Subpopulations" :open="popupSubpopulationsOpen" @dismiss="popupSubpopulationsOpen = !popupSubpopulationsOpen" width="50%">
    <fieldset class="space-y-2">
      <legend class="sr-only">Subpopulations</legend>
      <div class="relative flex items-start">
        <div class="flex items-center h-5">
          <app-checkbox id="subpopulation-checkbox-all" :checked="allCheckboxState" @click="internalToggleAll"></app-checkbox>
        </div>
        <div class="ml-3">
          <label for="subpopulation-checkbox-all" class="selectall">Select all subpopulations</label>
        </div>
      </div>
      <div v-for="(values, id) in enabledForDataType(availableSubpopulationValuesByVariableId)" v-bind:key="id" class="relative items-start">
        <app-disclosure-row
            :title="availableSubpopulationVariableMap[id].name"
            :itemId="id"
            openStateMutatorName="openSubpopulationVariableDisclosure"
            closeStateMutatorName="closeSubpopulationVariableDisclosure"
            openStateGetterName="subpopulationVariableDisclosureOpenState"
        >
          <div class="mt-3">
            <div v-for="subpopulation in ungroupedSubpopValues(values)" v-bind:key="subpopulation.id" class="relative flex items-start">
              <div class="flex items-center h-5" >
                <app-checkbox
                    :aria-label="`${ availableSubpopulationVariableMap[subpopulation.subpopulationVariableId].name }: ${ subpopulation.label }`"
                    :id="`subpopulation-checkbox-${subpopulation.id}`"
                    :checked="internalSelectedSubpopulationValues.has(subpopulation.id)"
                    @click="internalToggleSubpopulationValue(subpopulation.id)"
                />
              </div>
              <div class="ml-3">
                <label
                    :for="`subpopulation-checkbox-${subpopulation.id}`"
                    class="font-medium"
                >{{ subpopulation.label }}</label>
                <app-badge class="ml-1.5" v-if="subpopulation.selectionType === 'default'">Default</app-badge>
              </div>
            </div>
            <template v-if="ungroupedSubpopValues(values).length < values.length">
              <div v-for="groupId in groupIdsInSubpopValues(values)" v-bind:key="groupId">
                <h3>
                <div class="mt-4 mb-2 relative flex items-start">
                  <div class="flex items-center h-5" >
                    <app-checkbox
                        :aria-label="`Select all ${availableSubpopulationGroupMap[groupId].groupName} subpopulations`"
                        :id="`subpopulation-group-checkbox-${groupId}`"
                        :checked="subpopValuesInternallySelected(subpopValuesByGroupId(values, groupId))"
                        @click="internalToggleSubpopulationValueArray(subpopValuesByGroupId(values, groupId))"
                    />
                  </div>
                  <div class="ml-3">
                    <label :for="`subpopulation-group-checkbox-${groupId}`">{{ availableSubpopulationGroupMap[groupId].groupName }}</label>
                  </div>
                </div>
                </h3>

                <div v-for="groupedSubpop in subpopValuesByGroupId(values, groupId)" v-bind:key="groupedSubpop.id" class="pl-3 relative flex items-start">
                  <div class="flex items-center h-5" >
                    <app-checkbox
                        :aria-label="`${ availableSubpopulationVariableMap[groupedSubpop.subpopulationVariableId].name }: ${ groupedSubpop.label }`"
                        :id="`subpopulation-checkbox-${groupedSubpop.id}`"
                        :checked="internalSelectedSubpopulationValues.has(groupedSubpop.id)"
                        @click="internalToggleSubpopulationValue(groupedSubpop.id)"
                    />
                  </div>
                  <div class="ml-3">
                    <label
                        :for="`subpopulation-checkbox-${groupedSubpop.id}`"
                    >{{ groupedSubpop.label }}</label>
                    <app-badge class="ml-1.5" v-if="groupedSubpop.selectionType === 'default'">Default</app-badge>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </app-disclosure-row>
      </div>
    </fieldset>
    <template v-slot:footer>
      <app-button @click="save" size="l" color="focus">Save</app-button>
      <app-button @click="restoreDefaults" size="l" color="secondary">Restore Defaults</app-button>
      <app-button @click="cancel" size="l" color="secondary">Cancel</app-button>
    </template>
  </app-popup>
</template>

<script>

import AppBadge from '@/components/AppBadge';
import AppButton from '@/components/AppButton';
import AppDeleteButton from '@/components/AppDeleteButton';
import AppPopup from '@/components/AppPopup';
import AppDisclosureRow from '@/components/AppDisclosureRow';
import AppCheckbox from './AppCheckbox';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { UserIcon } from '@heroicons/vue/solid';

export default {
  data() {
    return {
      popupSubpopulationsOpen: false,
      internalSelectedSubpopulationValues: new Set(),
      openConfirmation: false
    };
  },

  computed: {
    ...mapGetters(['availableSubpopulationValues',
      'selectedSubpopulationValues',
      'selectedSubpopulationValuesText',
      'dataType',
      'availableSubpopulationValuesByVariableId',
      'selectedSubpopulationValuesByVariableId',
      'isStatisticsBySkillOnly',
      'selectedCountries',
      'selectedStatistics'
    ]),

    ...mapState([
      'availableSubpopulationVariableMap',
      'dataCart',
      'availableSubpopulationGroupIds',
      'availableSubpopulationGroupMap'
    ]),

    allCheckboxState() {
      if (this.internalSelectedSubpopulationValues.size === 0) {
        return false;
      } else if (this.internalSelectedSubpopulationValues.size === this.availableSubpopulationValues.length) {
        return true;
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapMutations([
      'setSelectedSubpopulationValues',
      'removeSubpopulationValueFromSelection'
    ]),

    internalToggleSubpopulationValue(id) {
      if (this.internalSelectedSubpopulationValues.has(id)) {
        this.internalSelectedSubpopulationValues.delete(id);
      } else {
        this.internalSelectedSubpopulationValues.add(id);
      }
    },

    internalToggleSubpopulationValueArray(values) {
      if (this.subpopValuesInternallySelected(values)) {
        values.forEach(v => this.internalSelectedSubpopulationValues.delete(v.id));
      } else {
        values.forEach(v => this.internalSelectedSubpopulationValues.add(v.id));
      }
    },

    internalToggleAll() {
      if (this.internalSelectedSubpopulationValues.size !== this.availableSubpopulationValues.length) {
        this.internalSelectedSubpopulationValues = new Set(this.availableSubpopulationValues.map(c => c.id));
      } else {
        this.internalSelectedSubpopulationValues.clear();
      }
    },

    unselectSubpopulationValue(id) {
      this.removeSubpopulationValueFromSelection(id);
    },

    clearSubpopulationValues() {
      this.setSelectedSubpopulationValues({ subpopulationValueIds: [] });
      this.internalSelectedSubpopulationValues.clear();
    },

    open() {
      this.internalSelectedSubpopulationValues = new Set([...this.selectedSubpopulationValues.map(c => c.id)]);
      this.popupSubpopulationsOpen = true;
    },

    cancel() {
      this.internalSelectedSubpopulationValues = new Set();
      this.popupSubpopulationsOpen = false;
    },

    cancelClear() {
      this.openConfirmation = false;
    },

    restoreDefaults() {
      this.internalSelectedSubpopulationValues = new Set(this.availableSubpopulationValues.filter(c => c.selectionType === 'default').map(c => c.id));
    },

    save() {
      this.setSelectedSubpopulationValues({ subpopulationValueIds: [...this.internalSelectedSubpopulationValues.values()] });
      this.popupSubpopulationsOpen = false;
    },

    enabledForDataType(subPopsByVarId) {
      const subPopVarMap = this.availableSubpopulationVariableMap;
      const filteredArr = Object.entries(subPopsByVarId).filter(([key, value]) => {
        const subpop = subPopVarMap[key];
        if (subpop.isStatisticsBySkillOnly && !this.isStatisticsBySkillOnly) {
          return false;
        } else {
          return true;
        }
      });

      return Object.fromEntries(filteredArr);
    },

    ungroupedSubpopValues(subpopValues) {
      return subpopValues.filter(s => !s.subpopulationGroup);
    },

    groupIdsInSubpopValues(subpopValues) {
      const subpopGroupIds = new Set(subpopValues.filter(s => s.subpopulationGroupId).map(s => s.subpopulationGroupId));
      return Array.from(subpopGroupIds).sort((a, b) => {
        return this.availableSubpopulationGroupMap[a].sequence - this.availableSubpopulationGroupMap[b].sequence;
      });
    },

    subpopValuesByGroupId(subpopValues, groupId) {
      return subpopValues.filter(s => s.subpopulationGroupId === groupId);
    },

    subpopValuesInternallySelected(subpopValues) {
      const found = subpopValues.filter(s => this.internalSelectedSubpopulationValues.has(s.id));
      if (found.length === subpopValues.length) {
        return true;
      } else if (found.length) {
        return null;
      } else {
        return false;
      }
    }
  },

  components: {
    AppBadge,
    AppButton,
    AppDeleteButton,
    AppPopup,
    AppDisclosureRow,
    AppCheckbox,
    UserIcon
  }
};

</script>
