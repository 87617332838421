<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0" @close="close">
      <div class="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-background-med bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="flex max-h-full-m-2 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full" :class="isConfirmation ? 'sm:max-w-md lg:max-w-2xl' : 'sm:max-w-lg lg:max-w-3xl'">
            <div class="flex flex-col mt-3 text-center sm:mt-0 sm:text-left w-full">
              <DialogTitle as="h2" class="flex-shrink-0 text-lg leading-6 px-4 sm:px-6 sm:pt-6 sm:ml-4">
                {{ title }}
              </DialogTitle>
              <div class="min-h-0 flex-shrink mt-2 px-4 pb-4 sm:px-6 sm:ml-4 overflow-y-auto">
                <slot></slot>
              </div>
              <div class="bg-background-light px-4 py-3" :class="!isConfirmation ? 'flex-shrink-0 sm:px-6 sm:flex sm:flex-row-reverse' : 'text-center'">
                <slot name="footer"></slot>
              </div>
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="bg-white rounded-md text-link-header hover:text-link-ha focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" @click="close">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    isConfirmation: {
      type: Boolean,
      default: false
    },
    title: String
  },

  methods: {
    close() {
      this.$emit('dismiss');
    }
  },

  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon
  }
};

</script>

<style lang="scss" scoped>

</style>
