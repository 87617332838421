export function groupStatisticsByCategory(stats) {
  const groupedStats = {};
  stats.forEach((s) => {
    const categoryName = s.earningsMeasureType.statisticType.statisticCategory.name;
    if (!groupedStats[categoryName]) {
      groupedStats[categoryName] = {};
    }
    groupedStats[categoryName][s.id] = s;
  });
  return groupedStats;
}

export function groupSubpopsByVariableId(subpopValuesArray) {
  const subpops = {};
  if (subpopValuesArray.length) {
    subpopValuesArray.forEach((s) => {
      if (!subpops[s.subpopulationVariableId]) {
        subpops[s.subpopulationVariableId] = [];
      }
      subpops[s.subpopulationVariableId].push(s);
    });
  }
  return subpops;
}
