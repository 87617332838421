// Simple trailing-edge function debounce
function debounce(func, delay) {
  let timeoutHandle = null;

  return function() {
    const context = this;
    const args = arguments;

    if (timeoutHandle !== null) {
      clearTimeout(timeoutHandle);
    }

    timeoutHandle = setTimeout(() => {
      func.apply(context, args);
      timeoutHandle = null;
    }, delay);
  };
}

export {
  debounce
};
