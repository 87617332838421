<template>
  <app-button class="align-middle" :ariaLabel="ariaLabel" size="xxxs" color="deleteLight"><XIcon class="w-4 h-4 text-primary"/></app-button>
</template>

<script>

import AppButton from '@/components/AppButton';
import { XIcon } from '@heroicons/vue/solid';

export default {
  props: {
    ariaLabel: {
      type: String,
      required: false
    }
  },
  components: {
    AppButton,
    XIcon
  }
};

</script>
